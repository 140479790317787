import React, { createContext, useEffect, useReducer, useState } from 'react';
import getContent from './content';
const EMPTY_STATE = {
    photos: {},
    galleries: {},
    privateGalleries: {},
    selectedGalleryPhotoIds: null,
    previouslySelectedPhotoId: null,
    loadedGalleryId: null
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'HYDRATE_PHOTOS': {
            return Object.assign(Object.assign({}, state), action.payload);
        }
        case 'SET_SELECTED_GALLERY_PHOTO_IDS': {
            return Object.assign(Object.assign({}, state), { selectedGalleryPhotoIds: action.payload.selectedGalleryPhotoIds, loadedGalleryId: action.payload.loadedGalleryId });
        }
        case 'BACK_TO_GALLERY': {
            return Object.assign(Object.assign({}, state), { previouslySelectedPhotoId: action.payload.previouslySelectedPhotoId, selectedGalleryPhotoIds: null });
        }
    }
};
const context = createContext({
    state: EMPTY_STATE,
    dispatch: () => { }
});
const ResultsContext = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, EMPTY_STATE);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(true);
        const { galleries, photos, privateGalleries } = getContent();
        dispatch({ type: 'HYDRATE_PHOTOS', payload: { photos, galleries, privateGalleries } });
        setIsLoading(false);
    }, []);
    if (isLoading) {
        return React.createElement("p", null, "Loading...");
    }
    const { Provider } = context;
    return React.createElement(Provider, { value: { state, dispatch } }, children);
};
export default ResultsContext;
export { context };
