var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Header } from "sharedComponents";
import { CONTENT_SPACING, ICON_COLOR, ICON_FONT_SIZES, TRANSITION_SPEED, } from "theme";
const TitleBar = ({ toggleNavigation, isNavigationVisible }) => {
    return (React.createElement(TitleBarWrapper, null,
        React.createElement(InternalLink, { to: "/" },
            React.createElement(Header, { size: "large" }, "Travis Bumgarner Photography")),
        React.createElement(NavigationOpen, { isNavigationVisible: isNavigationVisible, onClick: toggleNavigation, size: ICON_FONT_SIZES.l })));
};
const TitleBarWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  margin: 1rem;
`;
const InternalLink = styled(Link) `
  text-decoration: none;
  color: black;

  &:visited {
    color: black;
  }
`;
const NavigationOpen = styled((_a) => {
    var { isNavigationVisible } = _a, rest = __rest(_a, ["isNavigationVisible"]);
    return (React.createElement(FaBars, Object.assign({}, rest)));
}) `
  fill: ${ICON_COLOR.initial};
  padding-bottom: ${CONTENT_SPACING.l};
  padding-top: ${CONTENT_SPACING.l};
  transition: opacity ${TRANSITION_SPEED}s;
  opacity: ${(props) => (props.isNavigationVisible ? 0 : 1)};
  cursor: pointer;

  &:hover {
    fill: ${ICON_COLOR.hover};
  }
`;
export default TitleBar;
