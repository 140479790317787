var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useState } from 'react';
import { FaCaretRight } from 'react-icons/fa';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { Error } from 'sharedComponents';
import { GlobalStyle, ICON_COLOR, ICON_FONT_SIZES, TRANSITION_SPEED } from '../theme';
import { About, Home, Navigation, Photo, TitleBar } from './components';
import Gallery from './components/Gallery';
import Context from './context';
const App = () => {
    const [isNavigationVisible, setIsNavigationVisible] = useState(false);
    const toggleNavigation = useCallback(() => { setIsNavigationVisible(prev => !prev); }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(GlobalStyle, null),
        React.createElement(TitleBar, { isNavigationVisible: isNavigationVisible, toggleNavigation: toggleNavigation }),
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: React.createElement(Home, null) }),
            React.createElement(Route, { path: "/about", element: React.createElement(About, null) }),
            React.createElement(Route, { path: "/private/:gallerySlug" },
                React.createElement(Route, { index: true, element: React.createElement(Gallery, { privateGallery: true }) }),
                React.createElement(Route, { path: ":photoId", element: React.createElement(Photo, { privateGallery: true }) })),
            React.createElement(Route, { path: "/:gallerySlug" },
                React.createElement(Route, { index: true, element: React.createElement(Gallery, { privateGallery: false }) }),
                React.createElement(Route, { path: ":photoSlug", element: React.createElement(Photo, { privateGallery: false }) })),
            React.createElement(Route, { path: "/error500", element: React.createElement(Error, { value: "500" }) }),
            React.createElement(Route, { path: "*", element: React.createElement(Error, { value: "404" }) })),
        React.createElement(NavigationWrapper, { isNavigationVisible: isNavigationVisible },
            isNavigationVisible && (React.createElement(NavigationGutter, { onClick: toggleNavigation })),
            React.createElement(Navigation, { toggleNavigation: toggleNavigation }),
            React.createElement(NavigationClose, { isNavigationVisible: isNavigationVisible, onClick: toggleNavigation, size: ICON_FONT_SIZES.l }))));
};
const NavigationClose = styled((_a) => {
    var { isNavigationVisible } = _a, rest = __rest(_a, ["isNavigationVisible"]);
    return (React.createElement(FaCaretRight, Object.assign({}, rest)));
}) `
  position: absolute;
  top: 20px;
  left: 7px;
  transition: opacity ${TRANSITION_SPEED}s;
  opacity: ${(props) => (props.isNavigationVisible ? 1 : 0)};
  z-index: 999;
  fill: ${ICON_COLOR.initial};
  cursor: pointer;

  &:hover {
    fill: ${ICON_COLOR.hover};
  }
`;
const NavigationGutter = styled.div `
  position: fixed;
  left: 0;
  top: 0;
  z-index: 998;
`;
const NavigationWrapper = styled.div `
  box-sizing: border-box;
  display: flex;
  position: fixed;
  z-index: 999;
  top: 0;
  overflow: scroll;
  transition: right ${TRANSITION_SPEED}s;
  right: ${({ isNavigationVisible }) => isNavigationVisible ? '0' : '-100vw'};
`;
const WrappedApp = () => {
    return (React.createElement(Context, null,
        React.createElement(App, null)));
};
export default WrappedApp;
