import React, { useContext } from 'react';
import styled from 'styled-components';
import { context } from '../context';
import { getPhotoUrl } from '../utils';
import { Link } from 'react-router-dom';
import { Header, LazyImage } from 'sharedComponents';
const HomeImageWrapper = styled.div `
 display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const Home = () => {
    const { state: { galleries } } = useContext(context);
    return (React.createElement(HomeImageWrapper, null, Object.values(galleries).map(({ slug, title, previewSrc }) => {
        const url = getPhotoUrl({ isThumbnail: true, photoSrc: previewSrc, privateGalleryId: undefined });
        return (React.createElement(Link, { style: { textDecoration: 'none', textAlign: 'center', color: 'black' }, id: slug, to: `/${slug}`, key: slug },
            React.createElement(Header, { size: 'h2' }, title),
            React.createElement(LazyImage, { url: url })));
    })));
};
export default Home;
