import output from './output.json';
const getData = () => {
    return {
        photos: output.photos,
        galleries: output.galleries,
        privateGalleries: {
        // 'ricky-and-tif': rickyAndTif
        }
    };
};
export default getData;
