import React from 'react';
import styled from 'styled-components';
import { CONTENT_SPACING, FONT_FAMILY_HEADER, HEADER_FONT_SIZES } from '../theme';
const LargeHeader = styled.h1 `
    font-weight: 700;
    font-family: ${FONT_FAMILY_HEADER};
    text-transform: uppercase;
    margin-bottom: ${CONTENT_SPACING.l};
    margin-top: ${CONTENT_SPACING.l};
    font-size: ${HEADER_FONT_SIZES.l};
`;
const MediumHeader = styled.h2 `
    font-weight: 700;
    font-family: ${FONT_FAMILY_HEADER};
    text-transform: uppercase;
    margin-bottom: ${CONTENT_SPACING.m};
    margin-top: ${CONTENT_SPACING.m};
    font-size: ${HEADER_FONT_SIZES.m};
`;
const SmallHeader = styled.h3 `
    font-weight: 700;
    font-family: ${FONT_FAMILY_HEADER};
    font-size: ${HEADER_FONT_SIZES.s};
    margin-bottom: ${CONTENT_SPACING.s};
    margin-top: ${CONTENT_SPACING.s};
`;
const InlineHeader = styled.span `
    margin: 0 5px;
    text-transform: uppercase;
    font-weight: 700;
`;
const Header = ({ size, children }) => {
    switch (size) {
        case 'large':
            return React.createElement(LargeHeader, null, children);
        case 'medium':
            return React.createElement(MediumHeader, null, children);
        case 'small':
            return React.createElement(SmallHeader, null, children);
        case 'inline':
            return React.createElement(InlineHeader, null, children);
        default:
            return React.createElement(SmallHeader, null, children);
    }
};
export default Header;
