import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Header } from 'sharedComponents';
import { CONTENT_SPACING, FONT_FAMILY_HEADER, TEXT_FONT_SIZES } from '../../theme';
import { context } from '../context';
const Navigation = ({ toggleNavigation }) => {
    const { state: { galleries } } = useContext(context);
    const links = useMemo(() => {
        return Object.values(galleries)
            .sort((a, b) => (a.title > b.title ? 1 : -1))
            .map(({ title, slug }) => {
            return (React.createElement(LinkListItem, { key: slug, onClick: toggleNavigation },
                React.createElement(InternalLink, { to: `/${slug}` }, title)));
        });
    }, [galleries, toggleNavigation]);
    const aboutContent = [
        {
            title: 'Store',
            route: 'https://travisbumgarner.darkroom.com/',
            external: true
        },
        {
            title: 'Instagram',
            route: 'https://www.instagram.com/cameracoffeewander/',
            external: true
        },
        {
            title: 'Recognition',
            route: '/about',
            external: false
        },
        {
            title: 'Contact',
            route: 'https://www.linkedin.com/in/travisbumgarner/',
            external: true
        }
    ];
    const miscContent = [
        {
            title: 'Silly Side Projects',
            route: 'https://sillysideprojects.com/',
            external: true
        },
        {
            title: 'Engineering & Blog',
            route: 'https://travisbumgarner.com/',
            external: true
        }
    ];
    const aboutLinks = aboutContent.map(m => {
        return (React.createElement(LinkListItem, { key: m.title, onClick: toggleNavigation },
            React.createElement(ExternalLink, { target: m.external ? '_blank' : '', href: m.route }, m.title)));
    });
    const miscLinks = miscContent.map(m => {
        return (React.createElement(LinkListItem, { key: m.title, onClick: toggleNavigation },
            React.createElement(ExternalLink, { target: m.external ? '_blank' : '', href: m.route }, m.title)));
    });
    return (React.createElement(NavigationWrapper, null,
        React.createElement(SubNavigationWrapper, null,
            React.createElement(Header, { size: "medium" }, "Galleries"),
            React.createElement("ul", null, links)),
        React.createElement(SubNavigationWrapper, null,
            React.createElement(Header, { size: "medium" }, "About"),
            React.createElement("ul", null, aboutLinks)),
        React.createElement(SubNavigationWrapper, null,
            React.createElement(Header, { size: "medium" }, "Misc"),
            React.createElement("ul", null, miscLinks))));
};
const NavigationWrapper = styled.div `
    text-align: right;
    z-index: 999;
    padding: ${CONTENT_SPACING.l};
    border-left: 5px solid black;
    height: 100vh;
    box-sizing: border-box;
    flex-direction: column;
    width: 350px;
    background-color: rgba(255, 255, 255, 1);
    overflow: auto;
`;
const SubNavigationWrapper = styled.div `
    margin: ${CONTENT_SPACING.m} 0;

    margin-bottom: 2rem;
    :last-child {
        margin-top: 0;
    }
`;
const LinkListItem = styled.li `
    margin: ${CONTENT_SPACING.s} 0;

    &:hover {
        border-left: 5px solid rgb(74, 207, 160);
    }
`;
const sharedStyles = css `
    text-decoration: none;
    font-family: ${FONT_FAMILY_HEADER};
    font-size: ${TEXT_FONT_SIZES.m};
    color: black;
    width: 100%;
    display: inline-block;

    &:visited {
        color: black;
    }
`;
const InternalLink = styled(Link) `
    ${sharedStyles}
`;
const ExternalLink = styled.a `
    ${sharedStyles}
`;
export default Navigation;
